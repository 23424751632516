import React from 'react'
import { Box, Button, Form, Heading, Hero, Image } from 'react-bulma-components'
import Icon from './utils/Icon'
import logo from '../res/images/t_rond.png'
import GlobalContext from '../contexts/GlobalContext'
import Queries from '../tools/Query'

export default class SignIn extends React.Component {
	static contextType = GlobalContext

	constructor(props) {
		super(props)
		this.state = {
			showPassword: false,
		}
	}

	componentDidMount() {
		const iduser = new URLSearchParams(document.location.search).get('iduser')

		if (iduser) {
			setTimeout(() => {
				this.setState({ loading: true })
				Queries.isUserLogged(this.context, iduser, true)
					.then(({ user, apiVersion }) => {
						localStorage.removeItem('uid')
						localStorage.removeItem('date')
						this.signIn(user, apiVersion)
						this.setState({ loading: false, success: true, error: false })
					})
					.catch(err => {
						window.history.replaceState(null, null, window.location.pathname)
						this.setState({ loading: false })
					})
			}, 600)
		}
	}

	handleChange = keyboardEvent => this.setState({ [keyboardEvent.target.name]: keyboardEvent.target.value })

	log = formEvent => {
		formEvent.preventDefault()
		this.setState({ loading: true })

		const { username, password } = this.state
		const { translate } = this.context

		Queries.logUser(this.context, username, password)
			.then(({ user, apiVersion }) => {
				this.setState({ loading: false, success: true, error: false })

				this.signIn(user, apiVersion)
			})
			.catch(err => {
				this.setState({
					loading: false,
					error: err === 401 ? translate('global.errors.invalid_logins') : translate('global.errors.occurred'),
				})
			})
	}

	signIn = (res, apiVersion) => {
		const { rights, profile } = res
		const userData = res

		setTimeout(() => {
			this.context.signIn(rights, { ...userData, profil: profile }, apiVersion)

			localStorage.setItem('uid', res.id)
			localStorage.setItem('date', Date.now().toString())
		}, 400)
	}

	render() {
		const { translate } = this.context

		return (
			<Hero color={'primary'} size={'fullheight'} id={'signin'}>
				<p className="bg-white border z-50 px-3 py-2 rounded-md blur-sm transition-all hover:blur-none shadow-sm text-black absolute bottom-16 left-1/2 -translate-x-1/2">
					👋 Une nouvelle interface de Tabuléo Connect est disponible. Pour l'essayer, <a className="has-text-primary underline" href="https://connect-v3.tabuleo.fr">cliquez ici 👀</a>.
				</p>
				<Hero.Body className={'is-flex'} style={{ justifyContent: 'center' }}>
					<Box
						className={`fade-in-top ${this.state.success ? 'fade-out-bottom' : ''}`}
						style={{
							borderRadius: '20px',
							backgroundColor: 'rgba(211, 211, 211, 0.85)',
							backdropFilter: 'blur(8px)',
						}}
					>
						<Image style={{ margin: 'auto' }} size={64} src={logo} />
						<Heading style={{ marginTop: '1rem' }} subtitle textAlignment={'centered'} size={5} textColor={'grey-darker'}>
							Tabuléo connect
						</Heading>
						<form onSubmit={this.log}>
							<Form.Field>
								<Form.Label>{translate('global.username')}</Form.Label>
								<Form.Control iconLeft className={'has-text-dark'}>
									<Form.Input className={this.state.error ? 'is-danger' : ''} placeholder={translate('global.username')} name={'username'} value={this.state.username} onChange={this.handleChange} required />
									<Icon icon={'fal fa-user-alt'} className={'is-left'} />
									{this.state.error && <Form.Help color={'danger'}>{this.state.error}</Form.Help>}
								</Form.Control>
							</Form.Field>
							<Form.Field>
								<Form.Label>{translate('global.password')}</Form.Label>
								<Form.Control iconLeft iconRight>
									<Form.Input className={this.state.error ? 'is-danger' : ''} placeholder={translate('global.password')} type={this.state.showPassword ? 'text' : 'password'} name={'password'} value={this.state.password} onChange={this.handleChange} required />
									<Icon icon={'fal fa-fingerprint'} className={'is-left'} />
									<Icon key={this.state.showPassword ? 'show' : 'hidden'} onClick={() => this.setState({ showPassword: !this.state.showPassword })} className={'icon is-icon-clickable is-right'} icon={this.state.showPassword ? 'far fa-eye-slash' : 'far fa-eye'} />
								</Form.Control>
							</Form.Field>
							<Form.Field>
								<Form.Control>
									<Button loading={this.state.loading} color={this.state.success ? 'success' : this.state.error ? 'danger' : 'primary'} fullwidth>
										<Icon key={this.state.success} icon={this.state.success ? 'fal fa-check' : 'fal fa-sign-in'} />
										<span className="is-text-icon">{this.state.success ? translate('signin.success') : translate('signin.log_in')}</span>
									</Button>
								</Form.Control>
							</Form.Field>
						</form>
						<p style={{ marginTop: '2rem' }} className={'is-size-7 has-text-dark has-text-centered'}>
							v{process.env.REACT_APP_VERSION}
						</p>
					</Box>
				</Hero.Body>
			</Hero>
		)
	}
}
