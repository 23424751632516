import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import Icon from '../../utils/Icon'
import GlobalContext from '../../../contexts/GlobalContext'
// @ts-ignore
import { Form, Image } from 'react-bulma-components'
import CSV from '../../../tools/GeneratorCSV'
import ExcelExport from '../../utils/ExcelExport'
import { SchoolPlatform } from '../../types'

interface DropDownCsvProps {
	enabled: boolean
}

export default function DropdownCsv(props: DropDownCsvProps) {
	const context = useContext(GlobalContext)

	const { shouldMount, isLightTheme, school, translate, grid } = context
	const { addToast, getActiveUai } = context

	const apps = context.school.applications

	const platforms: SchoolPlatform[] = useMemo(() => school.platforms ?? [], [school])

	const [getDownloadCsv, setDownloadCsv] = useState(false)
	const [getFilenameCsv] = useState('')
	const [getDataCsv] = useState(undefined)
	const [getClassField, setClassField] = useState(false)

	const users = useMemo(() => ((grid && grid.getSelectedRows()) || []) as { Plateformes: string }[], [grid && grid.getSelectedRows()])
	/**
	 * Permet de récupérer les plateformes d'une plateforme d'un utilisateur
	 * @param platformId
	 */
	const usersFiltered = useCallback((platformId: string, platformForExport: boolean) => users.filter(({ Plateformes }) => platformForExport && Plateformes?.includes(platformId)), [users])

	const displayExportAllPlatforms = useMemo(() => platforms.some(({ platformId, platformForExport }) => !!usersFiltered(platformId, platformForExport).length), [usersFiltered, platforms])

	useEffect(() => {
		if (getDownloadCsv) {
			setDownloadCsv(false)
		}
	}, [getDownloadCsv])

	const changeField = (event: any) => {
		setClassField(event.target.value)
	}

	const exportCSV = (event: any, name: string) => {
		event.preventDefault()

		const users = grid.getSelectedRows()
		const uai = getActiveUai()

		const reportError = (error: any) => {
			addToast(error.toString(), { appearance: 'error' })
		}

		if (name === 'all') {
			return CSV.exportAllPlatforms(users, platforms, apps, uai, getClassField).catch(reportError)
		}
		const locationId = school.deployment?.asmInformations?.locationId

		switch (name) {
			/* Export de la grille tel qu'on le voit */
			case 'brut':
				return CSV.exportBrutGrid(grid, uai)

			/* "Export complet" : exporte **toutes** les data de la grille */
			case 'full':
				return CSV.exportFullDataBase(users, uai)

			/* "Export url" : export les informations de la grille ainsi que l'url scheme et web de tabuleo*/
			case 'url':
				return CSV.exportURL(users, uai)

			case 'teaching':
				return CSV.exportTeaching(users, uai)

			case 'asm':
				if (!locationId) {
					return addToast("Le location_id n'est pas défini dans les paramètres établissement.", { appearance: 'error' })
				}

				return CSV.exportASM(users, locationId, school.informations.name + ' SFTP')

			case 'claris':
				if (!locationId) {
					return addToast("Le location_id n'est pas défini dans les paramètres établissement.", { appearance: 'error' })
				}

				return CSV.exportOneRoster(users, locationId, school.informations.name + ' SFTP')

			/* export de chaque plateforme */
			default:
				const platform = platforms.find((platform: SchoolPlatform) => platform.platformId === name)
				if (!platform) {
					return addToast(translate('table.csv.platform_error'), { appearance: 'error' })
				}
				const { platformId, platformExportType, platformForExport } = platform
				return CSV.exportPlatform(usersFiltered(platformId, platformForExport), apps, platformId, platformExportType, uai, getClassField).catch(reportError)
		}
	}

	const isAsmExportConfigured = school.deployment?.asmInformations?.locationId

	return (
		<>
			{getDownloadCsv && <ExcelExport filename={getFilenameCsv} data={getDataCsv} />}
			<div className="dropdown is-hoverable" style={{ marginRight: '0.5rem' }}>
				<div className="dropdown-trigger">
					<button className="button is-info" disabled={!props.enabled} aria-haspopup="true" aria-controls="dropdown-menu">
						<Icon icon={'fad fa-file-csv'} />
						<span className={'text-icon'}>{translate('table.csv.title')}</span>
						<Icon icon={'fal fa-angle-down'} />
					</button>
				</div>
				<div className="dropdown-menu" id="dropdown-menu" role="menu">
					<div className={`dropdown-content fade-in-fwd-faster ${!isLightTheme ? 'has-background-black' : ''}`} hidden={!props.enabled}>
						{shouldMount('buttonExportCSV_CSVByEditors', 'Lecture') &&
							platforms
								?.filter(({ platformForExport }) => platformForExport)
								.sort((platformA, platformB) => platformA.platformOrder - platformB.platformOrder)
								.map(({ platformId, platformName, platformForExport, urls }) => {
									const hasPlatformUsers = !!usersFiltered(platformId, platformForExport).length
									return (
										<button key={platformId} disabled={!hasPlatformUsers} className={`dropdown-item is-inline-flex ${!hasPlatformUsers && 'is-disabled'}`} style={{ alignItems: 'center' }} onClick={event => exportCSV(event, platformId)}>
											<Image src={urls.find(({ name }) => name === 'picturePath')!.value ?? undefined} alt={platformId} size={16} />
											<span className="pl-2">{platformName}</span>
										</button>
									)
								})}

						{shouldMount('buttonExportCSV_exportTous', 'Lecture') && (
							// @ts-ignore
							<button disabled={!displayExportAllPlatforms} className={`dropdown-item ${!displayExportAllPlatforms && 'is-disabled'}`} onClick={event => exportCSV(event, 'all')}>
								{translate('table.csv.export_all')}
							</button>
						)}

						{shouldMount('buttonExportCSV_exportType', 'Lecture') && (
							<div className="dropdown-item">
								<Form.Control iconLeft className={'is-expanded'} size={'small'}>
									<div className="select is-small is-fullwidth">
										<select name="lang" onChange={changeField}>
											<option key={'level'} value={'level'}>
												{`${translate('global.by')} ${translate('global.level')}`}
											</option>
											<option key={'class'} value={'class'}>
												{`${translate('global.by')} ${translate('global.class')}`}
											</option>
										</select>
										<Icon icon={'fad fa-users-class'} className={'is-small is-left'} />
									</div>
								</Form.Control>
							</div>
						)}
						<hr className="dropdown-divider" />

						{shouldMount('buttonExportCSV_exportBrut', 'Lecture') && (
							<button type="button" className="dropdown-item is-flex" onClick={event => exportCSV(event, 'brut')}>
								<span className="text-icon">{translate('table.csv.export_grid')}</span>
								<span className="has-tooltip-arrow has-tooltip-right" data-tooltip={translate('table.csv.export_grid_help')}>
									<Icon icon={'fal fa-question-circle'} />
								</span>
							</button>
						)}

						{shouldMount('buttonExportCSV_exportFull', 'Lecture') && (
							<button type="button" className="dropdown-item is-flex" onClick={event => exportCSV(event, 'full')}>
								<span className="text-icon">{translate('table.csv.export_full')}</span>
								<span className="has-tooltip-arrow has-tooltip-right" data-tooltip={translate('table.csv.export_full_help')}>
									<Icon icon={'fal fa-question-circle'} />
								</span>
							</button>
						)}

						{shouldMount('buttonExportCSV_exportURL', 'Lecture') && (
							<button type="button" className="dropdown-item is-flex" onClick={event => exportCSV(event, 'url')}>
								<span className="text-icon">{translate('table.csv.export_URL')}</span>
								<span className="has-tooltip-arrow has-tooltip-right" data-tooltip={translate('table.csv.export_URL_help')}>
									<Icon icon={'fal fa-question-circle'} />
								</span>
							</button>
						)}

						{shouldMount('buttonExportCSV_exportTeaching', 'Lecture') && (
							<button type="button" className="dropdown-item is-flex" onClick={event => exportCSV(event, 'teaching')}>
								<span className="text-icon">{translate('table.csv.export_teaching')}</span>
								<span className="has-tooltip-arrow has-tooltip-right" data-tooltip={translate('table.csv.export_teaching_help')}>
									<Icon icon={'fal fa-question-circle'} />
								</span>
							</button>
						)}

						{shouldMount('buttonExportCSV_exportASM', 'Lecture') && (
							<button disabled={!isAsmExportConfigured} title={isAsmExportConfigured ? '' : 'Définissez le location_id dans les paramètres établissements'} className={`${!isAsmExportConfigured && 'is-disabled'} dropdown-item is-flex items-center`} onClick={event => exportCSV(event, 'asm')}>
								<span className="text-icon">{translate('table.csv.export_asm')}</span>
								<span className="has-tooltip-arrow has-tooltip-right" data-tooltip={translate('table.csv.export_asm_help')}>
									<Icon icon={'fab fa-apple'} />
								</span>
							</button>
						)}
						{shouldMount('buttonExportCSV_exportASM', 'Lecture') && (
							<button disabled={!isAsmExportConfigured} title={isAsmExportConfigured ? '' : 'Définissez le location_id dans les paramètres établissements'} className={`${!isAsmExportConfigured && 'is-disabled'} dropdown-item is-flex items-center`} onClick={event => exportCSV(event, 'claris')}>
								<span className="text-icon">{translate('table.csv.export_one_roster')}</span>
								<span className="has-tooltip-arrow has-tooltip-right" data-tooltip={translate('table.csv.export_asm_help')}>
									<Icon icon={'fab fa-apple'} />
								</span>
							</button>
						)}
					</div>
				</div>
			</div>
		</>
	)
}
