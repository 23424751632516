import JSZip from 'jszip'
import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'
import Queries from './Query'
import ConfigCSV from '../config/csv_config.json'
import { readString } from 'react-papaparse'

const isOnlyTeacher = (data: any[]) => {
	if (!data) {
		return false
	}

	let onlyTeacher = true
	data.forEach(e => {
		if (e.Profil !== 'Enseignant') {
			onlyTeacher = false
		}
	})
	return onlyTeacher
}

const isSafari = () => {
	return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
}

/**
 * Add classField to each student
 * classField is either class or level, it depends what the user chooses
 */
const addClassField = (data: any[], classField: boolean) =>
	data.map(student => ({
		...student,
		classField: classField ? student.Classe : student.Niveau,
	}))

const aoaToString = (data: any, separator: string, enclosingCharacter: any, returnCharacter = '\r\n') => {
	if (!data) {
		return
	}

	let csv = ''
	data.forEach((row: any[]) => {
		row = row.map((cell: any) => `${enclosingCharacter}${cell}${enclosingCharacter}`)
		csv += row.join(separator)
		csv += returnCharacter
	})

	return csv
}

const aoaToXlsxWb = (data: unknown[][]) => {
	const wb = XLSX.utils.book_new()
	const ws = XLSX.utils.aoa_to_sheet(data)
	XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
	return wb
}

const buildURI = (csv: BlobPart) => {
	const type = isSafari() ? 'application/csv;charset=utf-8' : 'text/csv;charset=utf-8'
	const blob = new Blob([csv], { type })
	const dataURI = `data:${type};charset=utf-8,${csv}`
	const URL = window.URL || window.webkitURL

	return typeof URL.createObjectURL === 'undefined' ? dataURI : URL.createObjectURL(blob)
}

/**
 * Fonction qui permet d'exporter un contenu en un fichier csv
 * Attention ne pas oublier le bom qui est nécessaire au bon encodage des caratere spéciaux dans le fichier csv !
 * @param filename
 * @param data
 * @param separator
 * @param enclosingChar
 * @param utf8
 */
const fileExport = (filename: string, data: any[], separator: string, enclosingChar: string, utf8 = true) => {
	const hiddenElement = document.createElement('a')
	const bom = '\uFEFF'
	let csv: any = utf8 ? bom + aoaToString(data, separator, enclosingChar) : aoaToString(data, separator, enclosingChar)
	hiddenElement.href = buildURI(csv)
	hiddenElement.target = '_blank'
	hiddenElement.download = `${filename}`
	hiddenElement.click()
}

/**
 * Get data of the specified users and find the login and password indices
 * from all the apps of the platform
 * @param data
 * @param platformId
 * @param apps
 */
const getPlatformLoginAndPasswordIndices = async (data: any[], platformId: string, apps: any) => {
	const csv: any = await Queries.getFullExport(
		null,
		data.map(student => student['ID utilisateur'])
	)

	const applicationsIdInPlatform = apps.filter((app: any) => app.platformId === platformId).map((app: any) => app.idApplication)

	const { data: values }: { data: any } = readString(csv)

	const loginIndices: number[] = []
	const passwordIndices: number[] = []
	const codeIndices: number[] = []

	values[0].forEach((value: string, index: number) => {
		applicationsIdInPlatform.forEach((appId: string) => {
			if (value.toLowerCase().includes(`${appId.toLowerCase()}-login`)) {
				loginIndices.push(index)
			} else if (value.toLowerCase().includes(`${appId.toLowerCase()}-mot de passe`)) {
				passwordIndices.push(index)
			} else if (value.toLowerCase().includes(`${appId.toLowerCase()}-code`)) {
				codeIndices.push(index)
			}
		})
	})

	return { loginIndices, passwordIndices, codeIndices, values }
}

/**
 * Find the login, password and code from the specified platform
 * @param data
 * @param loginIndices
 * @param passwordIndices
 * @param codeIndices
 */
export const getPlatformLoginPasswordAndCode = (data: any, loginIndices: number[], passwordIndices: number[], codeIndices: number[]) => {
	const loginIndex = loginIndices.find((index: number) => data[index])
	const passwordIndex = passwordIndices.find((index: number) => data[index])
	const codeIndex = codeIndices.find((index: number) => data[index])

	return {
		login: loginIndex ? data[loginIndex] : '',
		password: passwordIndex ? data[passwordIndex] : '',
		code: codeIndex ? data[codeIndex] : '',
	}
}

const getAppLoginAndPasswordForLib = async (data: any[]) => {
	const csv: any = await Queries.getFullExport(
		null,
		data.map(student => student['ID utilisateur'])
	)
	const { data: values }: { data: any } = readString(csv)
	let loginsIdx = {}
	const edulibApps = ['LIB', 'FLEX', 'MAX']
	edulibApps.forEach(appId => {
		let appLoginsIdx: any = {}
		appLoginsIdx.idxOfEtat = values[0].indexOf(`${appId}-etat`)
		appLoginsIdx.idxOfLogin = values[0].indexOf(`${appId}-login`)
		appLoginsIdx.idxOfPassword = values[0].indexOf(`${appId}-mot de passe`)
		appLoginsIdx.idxOfCode = values[0].indexOf(`${appId}-codes`)
		loginsIdx = { ...loginsIdx, [appId]: appLoginsIdx }
	})

	return { ...loginsIdx, values }
}

// @ts-ignore
const CSV = {
	exportFullDataBase: async (users: any, uai: any) => {
		const data = users
		const ids: any[] = []

		data.forEach((student: { [x: string]: any }) => ids.push(student['ID utilisateur']))

		const csv: any = await Queries.getFullExport(null, ids)

		const parser = readString(csv)

		fileExport(`${uai}_full.csv`, parser.data, ConfigCSV.PLTF_FULL.separator, ConfigCSV.PLTF_FULL.enclosingChar)
	},
	exportBrutGrid: async (grid: { getDataAsCsv: (arg0: { suppressQuotes: boolean; onlySelected: boolean; columnSeparator: string }) => any }, uai: any) => {
		let data = grid.getDataAsCsv({
			suppressQuotes: true,
			onlySelected: true,
			columnSeparator: ';',
		})

		data = data.split('\r\n').map((element: string) => element.split(';'))
		const header = data.shift()

		data.unshift(header)

		const passwords = []
		const idxOfPassword = header.indexOf('Password')

		for (let i = 1; i < data.length; i++) {
			passwords.push(data[i][idxOfPassword])
		}

		const decrypted: any = await Queries.decryptPassword(null, passwords)

		for (let i = 1; i < data.length; i++) {
			data[i][idxOfPassword] = decrypted[i - 1]
		}

		fileExport(`${uai}_brut.csv`, data, ConfigCSV.PLTF_BRUT.separator, ConfigCSV.PLTF_BRUT.enclosingChar)
	},
	exportURL: async (users: any[], uai: any) => {
		let csvData = [['Nom', 'Prenom', 'Niveau', 'Classe', 'URL SCHEME', 'URL WEB', 'Mail']]
		csvData = csvData.concat(users.map(student => [student.Nom, student.Prénom, student.Niveau, student.Classe, `tabuleo://IDutilisateur=${student['ID utilisateur']}`, `${window.location.origin}/?iduser=${student['ID utilisateur']}`, student.Mail]))

		fileExport(`${uai}_URL.csv`, csvData, ConfigCSV.PLTF_URL.separator, ConfigCSV.PLTF_URL.enclosingChar)
	},
	exportTeaching: async (users: any[], uai: any) => {
		const data = users
		const ids: any[] = []

		data.forEach((student: { [x: string]: any }) => ids.push(student['ID utilisateur']))

		const csv: any = await Queries.getTeachingExport(null, ids)

		const parser = readString(csv)

		fileExport(`${uai}_enseignements.csv`, parser.data, ConfigCSV.PLTF_FULL.separator, ConfigCSV.PLTF_FULL.enclosingChar)
	},

	// see https://tabuleo.atlassian.net/browse/DEV-340
	exportASM: async (users: any[], locationId: any, schoolname: string) => {
		const zip = new JSZip()

		const locationCsvData = [
			['location_id', 'location_name'],
			[locationId, schoolname],
		]

		const students = users.filter((student: any) => student.Profil === 'Eleve')
		const studentsCsvData = [['person_id', 'person_number', 'first_name', 'middle_name', 'last_name', 'grade_level', 'email_address', 'sis_username', 'password_policy', 'location_id']]

		students.forEach((student: any) => {
			studentsCsvData.push([student['ID utilisateur'], student['ID utilisateur'], student.Prénom, '', student.Nom, student.Niveau, student.Mail || '', student.Login, 4, locationId])
		})

		const staff = users.filter((teacher: any) => teacher.Profil !== 'Eleve')
		const staffCsvData = [['person_id', 'person_number', 'first_name', 'middle_name', 'last_name', 'grade_level', 'email_address', 'sis_username', 'password_policy', 'location_id']]

		staff.forEach((teacher: any) => {
			staffCsvData.push([teacher['ID utilisateur'], teacher['ID utilisateur'], teacher.Prénom, '', teacher.Nom, teacher.Niveau, teacher.Mail || '', teacher.Login, 8, locationId])
		})

		const classes: Set<string> = new Set()

		users.forEach(u => {
			const userClasse = u.Classe || u.Niveau

			classes.add(userClasse)
		})

		const classesCsvData = [['class_id', 'class_number', 'course_id', ...Array.from({ length: staff.length }).map((_, i) => (i === 0 ? 'instructor_id' : `instructor_id_${i + 1}`)), 'location_id']]

		Array.from(classes.values()).forEach(classe => {
			classesCsvData.push([classe, classe, classe, ...staff.map((t: any) => t['ID utilisateur']), locationId])
		})

		const coursesCsvData = [['course_id', 'course_name', 'location_id', 'course_number'], ...Array.from(classes.values()).map(classe => [classe, classe, locationId, classe])]

		const rosterCsvData = [['roster_id', 'class_id', 'student_id']]
		users
			.filter(u => u.Profil === 'Eleve')
			.forEach((u, i) => {
				const userClass = u.Classe || u.Niveau
				const classId = classesCsvData.find(c => c[1] === userClass)

				if (!classId) {
					return
				}

				rosterCsvData.push([classId[0] + '_' + u['ID utilisateur'], classId[0], u['ID utilisateur']])
			})

		// @ts-ignore
		zip.file(`rosters.csv`, aoaToString(rosterCsvData, ';', '', '\n'))
		// @ts-ignore
		zip.file(`locations.csv`, aoaToString(locationCsvData, ';', '', '\n'))
		// @ts-ignore
		zip.file(`students.csv`, aoaToString(studentsCsvData, ';', '', '\n'))
		// @ts-ignore
		zip.file(`staff.csv`, aoaToString(staffCsvData, ';', '', '\n'))
		// @ts-ignore
		zip.file(`courses.csv`, aoaToString(coursesCsvData, ';', '', '\n'))
		// @ts-ignore
		zip.file(`classes.csv`, aoaToString(classesCsvData, ';', '', '\n'))

		zip.generateAsync({ type: 'blob' }).then(content => saveAs(content, `ASM_${locationId}_${new Date().toLocaleDateString()}_${new Date().toLocaleTimeString()}.zip`))
	},

	exportOneRoster: async (users: any[], locationId: any, schoolname: string) => {
		const zip = new JSZip()

		const orgCsvData = [
			['sourcedId', 'name', 'status'],
			[locationId, schoolname, 'active'],
		]

		const usersCsvData = [['sourceId', 'identifier', 'givenName', 'middleName', 'familyName', 'grades', 'email', 'username', 'password', 'orgSourcedIDs', 'role', 'enabledUser', 'status']]

		users.forEach((user: any) => {
			usersCsvData.push([user['ID utilisateur'], user['ID utilisateur'], user.Prénom, '', user.Nom, user.Niveau, user.Mail || '', user.Login, user.Profil === 'Eleve' ? 4 : 8, locationId, user.Profil === 'Eleve' ? 'student' : 'teacher', '', ''])
		})

		const classes: Set<string> = new Set()

		users.forEach(u => {
			const userClasse = u.Classe || u.Niveau
			classes.add(userClasse)
		})

		const classCsvData = [['sourcedId', 'courseSourcedID', 'schoolSourcedID']]

		Array.from(classes.values()).forEach(classe => {
			classCsvData.push([classe, classe, locationId])
		})

		const coursesCsvData = [['sourcedId', 'orgSourcedID', 'title']]
		Array.from(classes.values()).forEach(classe => {
			coursesCsvData.push([classe, locationId, classe])
		})

		const enrollmentCsvData = [['sourcedId', 'classSourcedId', 'userSourcedId', 'role']]
		users
			.filter(u => u.Profil === 'Eleve')
			.forEach((u, i) => {
				const userClass = u.Classe || u.Niveau
				const classId = classCsvData.find(c => c[1] === userClass)

				if (!classId) {
					return
				}

				enrollmentCsvData.push([classId[0] + '_' + u['ID utilisateur'], classId[0], u['ID utilisateur'], u.Profil])
			})

		// @ts-ignore
		zip.file(`users.csv`, aoaToString(usersCsvData, ';', '', '\n'))
		// @ts-ignore
		zip.file(`courses.csv`, aoaToString(coursesCsvData, ';', '', '\n'))
		// @ts-ignore
		zip.file(`classes.csv`, aoaToString(classCsvData, ';', '', '\n'))
		// @ts-ignore
		zip.file(`enrollments.csv`, aoaToString(enrollmentCsvData, ';', '', '\n'))
		// @ts-ignore
		zip.file(`orgs.csv`, aoaToString(orgCsvData, ';', '', '\n'))

		zip.generateAsync({ type: 'blob' }).then(content => saveAs(content, `one_roster_v1_1_q${locationId}_${new Date().toLocaleDateString()}_${new Date().toLocaleTimeString()}.zip`))
	},

	exportAllPlatforms: async (users: any[], platforms: any, apps: any, uai: any, classField: any) => {
		const zip = new JSZip()
		const wopts = { bookType: 'xlsx', bookSST: false, type: 'binary' }
		const profil = isOnlyTeacher(users) ? 'Prof' : 'Eleve'
		for (const platform of platforms) {
			const { platformForExport, platformExportType } = platform
			const platformId = platform.platformId as keyof typeof ConfigCSV
			if (platformForExport) {
				const data = users.filter((user: any) => user.Plateformes?.includes(platformId))

				if (data.length) {
					let platformCsvData = await AppExport(platformId as keyof typeof CSV, apps, data, classField)
					if (!platformCsvData) {
						throw new Error('Une erreur est survenu lors de la génération du fichier CSV')
					}
					switch (platformExportType.toLowerCase()) {
						case 'csv':
							// @ts-ignore
							zip.file(`${uai}_${platformId}_${profil}.csv`, aoaToString(platformCsvData, ';', ConfigCSV[platformId].enclosingChar))
							break
						case 'xlsx':
							// @ts-ignore
							zip.file(`${uai}_${platformId}_${profil}.xlsx`, XLSX.write(aoaToXlsxWb(platformCsvData), wopts), { binary: true })
							break
						default:
							// @ts-ignore
							zip.file(`${uai}_${platformId}_${profil}.csv`, aoaToString(platformCsvData, ';', ConfigCSV[platformId].enclosingChar))
							break
					}
				}
			}
		}

		zip.generateAsync({ type: 'blob' }).then(content => saveAs(content, `${uai}_exportTous.zip`))
	},
	exportPlatform: async (users: any, apps: any, platformId: string, platformExportType: string, uai: any, classField: any) => {
		const configPlatformId = platformId as keyof typeof ConfigCSV
		const formatedData: any = await AppExport(platformId, apps, users, classField)
		const profil = isOnlyTeacher(users) ? 'Prof' : 'Eleve'
		const mustBeUtf8 = !['PLTF_CNS'].includes(platformId)
		const { separator, enclosingChar } = ConfigCSV[configPlatformId]
		switch (platformExportType.toLowerCase()) {
			case 'csv':
				fileExport(`${uai}_${platformId}_${profil}.csv`, formatedData, separator, enclosingChar, mustBeUtf8)
				break
			case 'xlsx':
				XLSX.writeFile(aoaToXlsxWb(formatedData), `${uai}_${platformId}_${profil}.xlsx`)
				break
			default:
				fileExport(`${uai}_${platformId}_${profil}.csv`, formatedData, separator, enclosingChar, mustBeUtf8)
				break
		}
	},
	/**
	 * OK
	 * @param data
	 * @param apps
	 * @returns {string[][]}
	 * @constructor
	 */
	PLTF_CNS: async (data: any[], apps: any): Promise<string[][]> => {
		const csvData = [['Nom', 'Prenom', 'Profil', 'Identifiant', 'Email', 'MDP', 'Identifiant_tablette', 'MDP_tablette', 'Classes', 'Groupes', 'Groupe_perso']]
		const { values, loginIndices, passwordIndices, codeIndices } = await getPlatformLoginAndPasswordIndices(data, 'PLTF_CNS', apps)

		return csvData.concat(
			data.map((student, idx) => {
				const { login, password } = getPlatformLoginPasswordAndCode(values[idx + 1], loginIndices, passwordIndices, codeIndices)
				const profil = student.Profil === 'Enseignant' ? 'P' : 'E'
				const options = []

				for (let column in student) {
					if (/Option[0-9]*/.test(column)) {
						if (student[column]) {
							options.push(student[column])
						}
					}
				}
				const groupes = options.join(',')

				return [student.Nom, student.Prénom, profil, login, '', password, '', '', student.classField, groupes, '']
			})
		)
	},
	/**
	 * OK
	 * @param data
	 * @param apps
	 * @returns {string[][]}
	 * @constructor
	 */
	PLTF_KNE: async (data: any[], apps: any): Promise<string[][]> => {
		let csvData = [['Typedeclasse', 'Profil', 'Nom', 'Prénom', 'Login', 'Mot de passe', 'Classe']]
		let groupeMax = 4
		const { values, loginIndices, passwordIndices, codeIndices } = await getPlatformLoginAndPasswordIndices(data, 'PLTF_KNE', apps)
		const onlyTeacher = isOnlyTeacher(data)
		csvData = csvData.concat(
			data.map((student, idx) => {
				const { login, password } = getPlatformLoginPasswordAndCode(values[idx + 1], loginIndices, passwordIndices, codeIndices)
				let i = 0
				let res = ['Classique', student.Profil, student.Nom, student.Prénom, login, password, student.classField]
				if (!onlyTeacher) {
					for (let j in student) {
						if (/Option[0-9]*/.test(j)) {
							if (student[j]) {
								i++
								res = res.concat(student[j])
							}
						}
					}
					if (groupeMax < i) {
						groupeMax = i
					}
				}
				return res
			})
		)
		if (!onlyTeacher) {
			csvData[0] = csvData[0].concat(Array.from({ length: groupeMax }, (e, i) => `Groupe${i + 1}`))
		}
		return csvData
	},
	/**
	 * OK
	 * @param data
	 * @returns {string[][]}
	 * @constructor
	 */
	PLTF_PV: async (data: any[], apps: any): Promise<string[][]> => {
		const csvData = [['GROUPE', 'NOM', 'PRENOM', 'EMAIL', 'IDENTIFIANT', 'mot de passe', 'type']]
		const { values, loginIndices, passwordIndices, codeIndices } = await getPlatformLoginAndPasswordIndices(data, 'PLTF_PV', apps)

		return csvData.concat(
			data.map((student, idx) => {
				const { login, password } = getPlatformLoginPasswordAndCode(values[idx + 1], loginIndices, passwordIndices, codeIndices)
				return [student.classField, student.Nom, student.Prénom, '', login, password, student.Profil === 'Enseignant' ? 'A' : 'E']
			})
		)
	},
	/**
	 * OK
	 * @param data
	 * @returns {string[][]}
	 * @constructor
	 */
	PLTF_KERB: async (data: any[], apps: any): Promise<string[][]> => {
		const csvData = [['Surname', 'First name', 'Admission Number', 'Year Group', 'Email address (optional)', 'Username (optional)']]

		const { values, loginIndices, passwordIndices, codeIndices } = await getPlatformLoginAndPasswordIndices(data, 'PLTF_KERB', apps)

		return csvData.concat(data.map((student, idx) => [student.Nom, student.Prénom, student['ID utilisateur'], student.classField, '', loginIndices]))
	},
	/**
	 * OK
	 * @param data
	 * @param apps
	 * @returns {string[][]}
	 * @constructor
	 */
	PLTF_CAMBRIDGE: async (data: any[], apps: any): Promise<string[][]> => {
		const csvData = [['ElevateId', 'Firstname', 'Lastname', 'Username', 'Password', 'User Type', 'Email', 'Year Group', 'Academic Year', 'Book Code']]

		const { values, loginIndices, passwordIndices, codeIndices } = await getPlatformLoginAndPasswordIndices(data, 'PLTF_CAMBRIDGE', apps)

		return csvData.concat(
			data.map((student, idx) => {
				const { login, password, code } = getPlatformLoginPasswordAndCode(values[idx + 1], loginIndices, passwordIndices, codeIndices)
				return ['', student.Prénom, student.Nom, login, password, student.Profil === 'Enseignant' ? 'Teacher' : 'Student', login, '', '', code]
			})
		)
	},
	/**
	 * OK
	 * @param data
	 * @param apps
	 * @returns {string[][]}
	 * @constructor
	 */
	PLTF_LLS2020: async (data: any[], apps: any): Promise<string[][]> => {
		const csvData = [['Profil', 'Email (optionnel pour les eleves)', 'Nom', 'Prenom', 'Matière', 'Mot de passe (optionnel)', 'customUsername']]
		const { values, loginIndices, passwordIndices, codeIndices } = await getPlatformLoginAndPasswordIndices(data, 'PLTF_LLS', apps)

		return csvData.concat(
			data.map((student, idx) => {
				const { login, password } = getPlatformLoginPasswordAndCode(values[idx + 1], loginIndices, passwordIndices, codeIndices)
				return [student.Profil.toLowerCase(), '', student.Nom, student.Prénom, '', password, login]
			})
		)
	},
	/**
	 * OK
	 * @param data
	 * @returns {string[][]}
	 * @constructor
	 */
	PLTF_EDULIB: async (data: any[]): Promise<string[][]> => {
		const csvData = [['Nom', 'Prénom', 'Email', 'Identifiant', 'Mot de passe', 'Code classe', 'Code groupe']]

		const onlyTeacher = isOnlyTeacher(data)
		if (onlyTeacher) {
			csvData[0].splice(5, 0, 'Matière')
		}

		// @ts-ignore
		const { FLEX, LIB, MAX, values } = await getAppLoginAndPasswordForLib(data)
		return csvData.concat(
			data.map((student, idx) => {
				const optionsArray = []
				for (let j in student) {
					if (/Option[0-9]*/.test(j)) {
						if (student[j]) {
							optionsArray.push(student[j])
						}
					}
				}
				//TODO: le caractere du join doit etre en fonction du type de fichier (csv ou xlsx)
				const options = optionsArray.join(';')

				let appId: any = {}
				const userData = values[idx + 1]

				if (userData[LIB.idxOfEtat] == '1') {
					appId = LIB
				} else if (userData[FLEX.idxOfEtat] == '1') {
					appId = FLEX
				} else if (userData[MAX.idxOfEtat] == '1') {
					appId = MAX
				}

				const login = userData[appId.idxOfLogin] || ''
				const password = userData[appId.idxOfPassword] || ''
				const email = ''

				let userValue = [student.Nom, student.Prénom, email, login, password, student.classField, options]
				let teacherValue = [student.Nom, student.Prénom, email, login, password, student.Classe, student.Niveau]
				return onlyTeacher ? teacherValue : userValue
			})
		)
	},
	/**
	 * OK
	 * @param data
	 * @param apps
	 * @returns {string[][]}
	 * @constructor
	 */
	PLTF_DYLE: async (data: any[], apps: any): Promise<string[][]> => {
		const csvData = [['Action *', 'User ID', 'Type *', 'Username/Email', 'Password', 'First name *', 'Middle name', 'Last name *', 'UPN', 'Access to application (Y/N) *']]

		const type = (student: { Profil: string }) => (student.Profil === 'Enseignant' ? 'T' : 'S')
		const { values, loginIndices, passwordIndices, codeIndices } = await getPlatformLoginAndPasswordIndices(data, 'PLTF_HODDER', apps)

		return csvData.concat(
			data.map((student, idx) => {
				const { login, password } = getPlatformLoginPasswordAndCode(values[idx + 1], loginIndices, passwordIndices, codeIndices)
				return ['A', '', type(student), login, password, student.Prénom, '', student.Nom, '', 'Yes']
			})
		)
	},
	PLTF_TRHMH: async (data: any[], apps: any) => {
		const csvData = [['email', 'login', 'firstname', 'lastname', 'password', 'role', 'grade level', 'Send confirm? (yes/no)']]

		const { values, loginIndices, passwordIndices, codeIndices } = await getPlatformLoginAndPasswordIndices(data, 'PLTF_HMH', apps)

		const sortedByClass = data
			.sort((s1, s2) => s1.Classe.localeCompare(s2.Classe))
			.map((student, idx) => {
				const { login, password } = getPlatformLoginPasswordAndCode(values[idx + 1], loginIndices, passwordIndices, codeIndices)
				return {
					classe: student.Classe,
					data: ['', login, student.Prénom, student.Nom, password, student.Profil, '', 'no'],
				}
			})

		const final = []
		let previousClass = null

		for (let i = 0; i < sortedByClass.length; i++) {
			const { classe, data } = sortedByClass[i]

			if (previousClass !== classe) {
				previousClass = classe

				final.push(['', '', '', '', '', '', ''], ['Copy', classe, '', '', '', '', ''])
			}

			final.push(data)
		}

		return csvData.concat(final)
	},
	PLTF_KWYK: async (data: any[], apps: any): Promise<string[][]> => {
		const csvData = [['Nom', 'Prenom', 'Classe', 'Cas', 'Enseignant']]

		const profil = (student: { Profil: string }) => (student.Profil === 'Enseignant' ? 'Oui' : '')
		const { values, loginIndices, passwordIndices, codeIndices } = await getPlatformLoginAndPasswordIndices(data, 'PLTF_KWYK', apps)

		return csvData.concat(
			data.map((student, idx) => {
				const { login, password } = getPlatformLoginPasswordAndCode(values[idx + 1], loginIndices, passwordIndices, codeIndices)
				return [student.Nom, student.Prénom, student.classe | student.level, login, profil(student)]
			})
		)
	},
}

const AppExport = async (platformId: string, apps: any, data: any, classField: string) => {
	const csvPlatformId = platformId as keyof typeof CSV
	try {
		data = addClassField(data, classField === 'class')

		const passwords = []

		for (const user of data) {
			passwords.push(user.Password)
		}

		const decrypted: any = await Queries.decryptPassword(null, passwords)

		for (let i = 0; i < data.length; i++) {
			data[i].Password = decrypted[i]
		}

		// @ts-ignore
		return await CSV[csvPlatformId](data, apps)
	} catch (e) {
		console.error(e)
		throw new Error(`l'export ${platformId} n'est pas encore disponible.`)
	}
}

export default CSV
